import React, { useState, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import P from "../../core/P"

export const BlogPopup = props => {
  const { setAddPopup } = props
  // eslint-disable-next-line no-unused-vars
  const [nda, setNda] = useState(false)
  const [message, setMessage] = useState(undefined)
  const [status, setStatus] = useState(undefined)
  const [current, setCurrent] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [isRecaptcha, setIsRecaptcha] = useState(false)
  const captchaRef = useRef(null)
  const [recaptchaComponent, setRecaptchaComponent] = useState(null)
  useEffect(() => {
    setCurrent(window.location.pathname)
  }, [])
  useEffect(() => {
    const timeout = setTimeout(async () => {
      // Dynamically import the package after 9 seconds
      const { default: ReCAPTCHA } = await import("react-google-recaptcha")
      setRecaptchaComponent(
        <ReCAPTCHA
          sitekey="6Ld19jMnAAAAAH9Y24lduuFjNMmBZEWaTwLwxLQE"
          onChange={onChangeRecaptcha}
          onErrored={onErroredRecaptcha}
          onExpired={onExpiredRecaptcha}
          ref={captchaRef}
          className="recaptch"
        />
      )
    }, 5000) // Delay of 9 seconds

    return () => clearTimeout(timeout)
  }, [])

  const onChangeRecaptcha = () => {
    setIsRecaptcha(true)
  }
  const onErroredRecaptcha = () => {
    setIsRecaptcha(false)
  }
  const onExpiredRecaptcha = () => {
    setIsRecaptcha(false)
  }

  const onSubmit = e => {
    e.preventDefault()
    setDisabled(true)
    const form = e.target
    const data = new FormData(form)
    //data.set("companyName", "")
    data.set("recaptcha_token", captchaRef.current.getValue())

    //     // eslint-disable-next-line no-unused-vars
    const response = fetch(
      "https://api.webmobtech.com/wmtblog/api/web/contactus/short_contact_submit",
      {
        method: "POST",
        body: data,
      }
    )
      .then(response => {
        return response.json()
      })
      .then(res => {
        window.scrollTo(0, 0)
        if (res.meta.status === "ok") {
          document.getElementById("contact-form").reset()
          setNda(false)
          localStorage.setItem("form_submission_success", "true");
          setAddPopup(false)
        }
        setDisabled(false)
        setMessage(res.meta.message)
        setStatus(res.meta.status)
      })
  }

  const Notification = ({ status, message }) => {
    if (!status) return null
    return (
      status && (
        <div
          className={` rounded-md ${
            status === "ok" ? "bg-green-50" : "bg-red-50"
          } p-4`}
        >
          <div className="flex">
            <div className="ml-3">
              <p
                className={`text-sm leading-5 font-medium ${
                  status === "ok" ? "text-green-800" : "text-red-800"
                }`}
              >
                {message}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={() => setStatus(false)}
                  className={`inline-flex rounded-md p-1.5 ${
                    status === "ok"
                      ? "text-green-500 focus:bg-green-100 hover:bg-green-100"
                      : "text-red-500 focus:bg-red-100 hover:bg-red-100"
                  }  focus:outline-none transition ease-in-out duration-150`}
                >
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    )
  }

  return (
      <div>
        <div
          className="fixed z-30 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          {/* Flexbox parent container */}
          <div className="flex items-center justify-center min-h-screen text-center px-4 sm:p-0">
            {/* Background overlay */}
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            {/* Modal container */}
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle lg:w-3/5 md:w-2/4 lg:max-w-screen-xl md:max-w-screen-xl max-w-full w-full p-6">
              <div>
                <P
                  title={`Let's connect`}
                  className={`lg:text-2xl md:text-2xl text-shark-400 pb-4 font-medium max-w-6xl`}
                  noPad
                  noCenter
                />
                <div className="flex-col place-items-center md:place-items-start pb-8 hidden md:block lg:block">
                  <hr className="w-40 border-b-2 border-blue-500 bg-blue-500" />
                </div>
              </div>
              <div className="grid lg:grid-cols-2 lg:gap-4 grid-cols-1 gap-6">
                <form
                  id="contact-form"
                  method="POST"
                  className="grid grid-cols-1 gap-4 md:grid-cols-1 md:gap-5 lg:grid-cols-2 lg:gap-6"
                  onSubmit={e => onSubmit(e)}
                >
                  {status && (
                    <div className="sm:col-span-2">
                      <Notification status={status} message={message} />
                    </div>
                  )}
                  <div className="sm:col-span-2">
                    <div className="mt-1 relative shadow-sm">
                      <input
                        placeholder="Name *"
                        id="fullName"
                        name="fullName"
                        required
                        className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="mt-1 relative shadow-sm">
                      <input
                        placeholder="Email *"
                        id="emailId"
                        name="emailId"
                        type="email"
                        required
                        className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="mt-1 relative shadow-sm">
                      <input
                        placeholder="Company Name *"
                        id="companyName"
                        name="companyName"
                        required
                        className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2 mt-1 relative ">
                    {recaptchaComponent}
                  </div>
                  <div className="sm:col-span-2">
                    <span className="inline-flex shadow-sm">
                      <div className="mt-5 inline-flex">
                        {disabled ? (
                          <button
                            type="submit"
                            disabled
                            className="bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white opacity-75 cursor-not-allowed rounded"
                          >
                            Download Brochure
                          </button>
                        ) : (
                          <button
                            disabled={!isRecaptcha ? true : false}
                            type="submit"
                            className={
                              isRecaptcha
                                ? "bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
                                : "bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white opacity-75 cursor-not-allowed rounded"
                            }
                          >
                            Download Brochure
                          </button>
                        )}
                      </div>
                    </span>
                  </div>
                </form>
                <div className="lg:block hidden justify-center items-center self-center">
                  <img
                    className="lg:h-auto lg:w-full md:w-full md:h-auto w-full h-auto object-cover"
                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/08/New-Illustration.png"
                    alt={"Support team"}
                    title="Support team"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
const input_classes = `
input_custom_placeholder_white
rounded-md bg-transparent 
form-input border-white 
border-opacity-50 
focus:border-white 
text-white 
py-3 
mt-2 
px-4 
block 
w-full 
border 
border-solid 
transition ease-in-out duration-150
`

export default BlogPopup
