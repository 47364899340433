import React, { useState, useEffect } from "react"
import BlogPopup from "../../../components/blocks/Forms/BlogPopup"

const CTAButtonBroucher = ({ title, subtitle, action1, action2 }) => {
  //const { button, title, subtitle } = CTABrochureButtonBlock
console.log("title",title);
console.log("subtitle",subtitle)
console.log("action1",action1);
console.log("action2",action2)

  const [addPopup, setAddPopup] = useState(false)
  return (
    <>
      {/* Conditional Popup */}
      {addPopup && <BlogPopup setAddPopup={setAddPopup} />}

      <div className="flex justify-center items-center bg-blue-50 min-h-screen p-5 w-full">
        <div className="bg-white shadow-lg rounded-lg max-w-4xl overflow-hidden flex flex-col lg:flex-row">
          {/* Left Section - Image */}
          <div className="w-full lg:w-1/2">
            <img
              src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/12/ces-pass.png"
              alt="Promo Image"
              className="w-full h-auto object-cover"
            />
          </div>

          {/* Right Section - Promo Details */}
          <div className="w-full lg:w-1/2 p-6 flex flex-col">
            {/* Text */}
            <h3 className="text-2xl font-bold text-gray-800 mb-4">{title}</h3>

            {/* Button */}
            <button
              className="self-start bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition mt-auto"
              onClick={() => setAddPopup(true)} // Open Popup
            >
              {action1.title}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CTAButtonBroucher
